<template>
  <div class="mainwrap coupenlist" v-loading="loading">
    <div class="title">
      優惠券明細
      <span>
        <el-button type="primary" plain @click="cancel">返回</el-button>
      </span>
    </div>
    <div class="searchform">
      <el-form
        label-width="90px"
        :inline="true"
        :model="searchForm"
        class="demo-form-inline"
      >
        <el-form-item label="會員電話：">
          <el-input v-model="searchForm.Mobile" placeholder="請輸入會員電話"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSearch">查询</el-button>
          <el-button @click="reSet">重置</el-button>
          <!-- <el-button type="primary" @click="getRefreshActivity()">更 新</el-button> -->
          <el-button type="success" @click="downLoad" :disabled="disabledDown">下載</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="searchform">
      <el-tag class="ml-1 ms1" type="large">名稱：{{name}}</el-tag>
      <el-tag class="ml-1 ms1" type="large">類型：{{types}}</el-tag>
      <el-tag class="ml-1 ms1" type="success">派發總量：{{totalCount}}</el-tag>
      <el-tag class="ml-1 ms1" type="success">已領取數量：{{receivedCount}}</el-tag>
      <el-tag class="ml-1 " type="success">核銷數量：{{writeoffCount}}</el-tag>
    </div>


    <div class="tablewrap">
      <el-table :data="tableData" style="width: 100%" stripe>
        <el-table-column prop="code" label="券號"> </el-table-column>
        <el-table-column prop="status" label="狀態"> </el-table-column>
        <el-table-column prop="member" label="會員名稱"> </el-table-column>
        <el-table-column prop="mobile" label="會員電話"> </el-table-column>
        <el-table-column  prop="receiveTime" label="領取時間"></el-table-column>
        <el-table-column prop="usedTime" label="使用時間"></el-table-column>
        <el-table-column prop="paymentCounter" label="使用店別"></el-table-column>
        <el-table-column prop="paymentAmount" label="消費金額"></el-table-column>
        <el-table-column label="消費明細">
          <template slot-scope="scope">
            <el-button v-if="scope.row.detail" type="primary" @click="showDis(scope.row.detail)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <div class="pagination">
      <el-pagination
        background
        :hide-on-single-page="isSinglePage"
        layout="prev, pager, next,jumper"
        @current-change="handleCurrentChange"
        :page-size="pageSize"
        :current-page="currentPage"
        :total="tableDataTotal"
      >
      </el-pagination>
    </div>
      <el-dialog title="详情" :visible.sync="dialogTableVisible">
        <el-table v-if="dialogTableVisible" :data="openList">
          <el-table-column v-for="(val,i,index) in openList[0]" :key="index" :prop="i">
            <template slot="header">
             {{i}}
            </template>
          </el-table-column>
        </el-table>
      </el-dialog>


  </div>
</template>

<script>
import * as api from "./api";
export default {
  data(){
    return{
      dialogTableVisible:false,
      openList:[],
      loading:false,
      disabledDown:true,
      couponForm:{
        id:"",
      },
      searchForm:{
        Mobile:"",
      },
      tableData:[],
      totalCount:'',
      receivedCount:'',
      writeoffCount:'',
      isSinglePage:false,
      pageSize:10,
      currentPage:1,
      tableDataTotal:0,
      types:'',
      name:''
    }
  },
  created(){
    let queryObj = this.$route.query || {};
    if (queryObj.type && queryObj.type === "edit" && queryObj.id) {
        this.couponForm.id=queryObj.id;
        this.getCouponList();
        this.getCouponDetails(queryObj.id);
    }
  },
  methods:{
    showDis(e){
      this.openList = []
      this.dialogTableVisible = true
      this.openList = JSON.parse(e)
    },
    handleCurrentChange(val){
      this.currentPage=val;
      this.getCouponList();
    },
    // 返回上一級
    cancel() {
      this.$router.push({path:"/cms/couponlist"});
    },
    // getRefreshActivity() {
    //   this.loading=true;
    //   api.getRefreshActivity().then(res=> {
    //     if(res.data){
    //       this.getCouponList();
    //     }
    //     this.loading=false;
    //   })
    // },
    onSearch(){
      this.currentPage=1;
      this.getCouponList();
    },
    reSet(){
      this.searchForm={
        Mobile:"",
      }
      this.currentPage=1;
      this.getCouponList();
    },
    // 下載
    downLoad(index){
        this.disabledDown=true;
        let params={
          ActivityId:this.couponForm.id,
          Mobile:this.searchForm.Mobile,
          SkipCount:(this.currentPage-1)*this.pageSize,
          MaxResultCount:this.pageSize
        }
        for (let key in params){
            if(params[key]===""){
                delete params[key];
            }
        }
        this.loading=true;
        console.log(params)
        api.exportRecordReport(params).then(res=>{
            if(res.systemCode===200){
                window.open(res.data,"_blank")
            }
            this.disabledDown=false;
            this.loading=false;
        })
    },
    getCouponDetails(id) {
      api.getCouponDetails(id).then(res=>{
        if(res.systemCode===200){
          this.receivedCount=res.data.receivedCount;
          this.writeoffCount=res.data.writeoffCount;
          this.totalCount=res.data.totalCount;
          this.types = res.data.type;
          this.name = res.data.name;
        }
      }).catch((error) => {
        console.error(error);
      });
    },
    getCouponList(){
      this.loading=true;
      this.disabledDown=true;
      api.getDetails({
        ActivityId:this.couponForm.id,
        Mobile:this.searchForm.Mobile,
        SkipCount:(this.currentPage-1)*this.pageSize,
        MaxResultCount:this.pageSize
      }).then(res=>{
        if(res.systemCode===200){
          this.tableData=res.data.items;
          this.tableDataTotal=res.data.totalCount;
        }
        this.loading=false;
        this.disabledDown=false;
      }).catch((error) => {
        console.error(error);
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.coupenlist {
  .titles {
    display: flex;
    align-items: center;
    .bens {
      margin-right: 10px;
      display: inline-block;
    }
  }
  .searchform {
    // padding: 20px;
    padding: 20px 0px;
    border-bottom: 1px solid #ccc;
    .ms1 {
      margin-right: 10px;
    }
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
