import { getRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 列表
export const getCouponList=(params)=>{
    return getRequest(API.couponList,params);
}
// 詳情
export const getCouponDetails=(params)=>{
    return getRequest(API.couponList+'/'+params);
}
// 编辑
export const couponEdit=(params)=>{
    return postRequest(API.couponEdit,params);
}
// 上傳圖片
export const uploadImage=(params)=>{
    return postFormReq(API.uploadImage,params);
}
// 獲取優惠卷明細
export const getDetails=(params)=>{
    return getRequest(API.getDetails,params);
}
// 獲取抵用券
export const getTypeSelectList=()=>{
    return getRequest(API.typeSelectList);
}
// 上傳文件
export const uploadFile=(params)=>{
    return postFormReq(API.uploadFile,params);
}
// 會員統計
export const getRefreshActivity=()=>{
    return postRequest(API.refreshActivity);
}
// 匯出優惠券明細
export const exportRecordReport=(params)=>{
    return postRequest(API.exportRecordReport,params);
}
// 獲取優惠券列表
export const couponMessageList=()=>{
    return getRequest(API.couponMessageList);
}
// refreshActivity